import React, { useState } from "react"
import ReactGA from "react-ga"
import Recaptcha from "react-recaptcha"
import { NoSideBarLayout } from "../components/layouts"
import Button from "../components/button"
import SEO from "../components/seo"
import Row from "../components/row"
import Column from "../components/column"
import FormInput from "../components/input"
import ErrorBoundary from "../components/errorBoundry"
import setFormControl from "../utils/setFormControl"
import { Link } from "gatsby"

const ContactPage = () => {
  const [captchaResponse, setCaptchaResponse] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState(null)
  const [submitStatus, setSubmitStatus] = useState<
    "loading" | "success" | "none"
  >("none")

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!name) {
      setError("Please enter your name.")
      return
    }

    if (!email) {
      setError("Please enter a valid email address.")
      return
    }

    if (!message) {
      setError("Please enter your message.")
      return
    }

    if (!captchaResponse) {
      setError("You must complete the captcha.")
      return
    }

    setError(null)

    try {
      setSubmitStatus("loading")
      const res = await fetch(process.env.GATSBY_CONTACT_FORM_URL, {
        method: "post",
        body: JSON.stringify({
          name,
          email,
          message,
          captchaResponse,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(res => res.text())

      if (res) {
        setError(res)
        setSubmitStatus("none")
        return
      }

      ReactGA.event({
        category: "Forms",
        action: "Submitted",
        label: "Contact Form Submitted",
      })

      setSubmitStatus("success")
      return
    } catch (e) {
      console.error(e)
      setError(e.message)
      setSubmitStatus("none")
      ReactGA.exception({
        description: "Failed to submit contact form",
        fatal: false,
      })
      return
    }
  }

  return (
    <NoSideBarLayout>
      <SEO title="Get In Touch" />
      <h1>Contact Us</h1>
      <Row>
        <Column large={7}>
          <p>
            Use this form to get in touch with. We'll aim to respond as quickly
            as possible.
          </p>
          <p>
            If you are a from a company wishing to work with us commercially, please visit our <Link to="/sponsor-interest">"Work With Us" form</Link>.
          </p>
          {error && <p className="form-error">{error}</p>}
          {submitStatus === "success" && (
            <p className="form-success">Contact Form Submitted Successfully.</p>
          )}
          <form onSubmit={onSubmit}>
            <FormInput
              id="contact-form-name"
              label="Name"
              type="text"
              name="name"
              required
              value={name}
              onChange={setFormControl(setName)}
            />
            <FormInput
              id="contact-form-email"
              label="Email Address"
              type="email"
              name="email"
              required
              value={email}
              onChange={setFormControl(setEmail)}
            />
            <FormInput
              id="contact-form-message"
              label="Message"
              type="textarea"
              name="message"
              required
              value={message}
              onChange={setFormControl(setMessage)}
            />
            <div className="form-control">
              <ErrorBoundary message="Failed to load ReCaptcha, try navigating away and back to this page.">
                <Recaptcha
                  sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                  verifyCallback={setCaptchaResponse}
                  expiredCallback={() => setCaptchaResponse("")}
                  size="normal"
                />
              </ErrorBoundary>
            </div>
            {submitStatus === "none" && (
              <div className="form-control">
                <Button as="button">Submit</Button>
              </div>
            )}
          </form>
        </Column>
        <Column large={5}>
          <p>Alternatively, you can write to us at:</p>
          <p>
            Hackathons UK
            <br />
            Suite 2202
            <br />
            109 Vernon House, Friar Lane
            <br />
            Nottingham, Nottinghamshire
            <br />
            NG1 6DQ
          </p>
        </Column>
      </Row>
    </NoSideBarLayout>
  )
}

export default ContactPage
